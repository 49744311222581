
import red from '@material-ui/core/colors/red';

export const fuseThemesConfig = {
    default    : {
        palette: {
            type     : 'light',
            background:{
                nav:"#1c5429"
            },
            primary  :   {
                light: '#254A8C',
                main : '#eaeaea',
                dark : '#adadadad',
              
            },
            secondary: {
                light:"#388E3C",
                main :"#D3041F",
                dark : "#333",
                contrastText: '#fff',
                lighttext:"#FAFAFA"
            },
            action:{
                light : '#5fa463',
                main : '#388e3c',
                dark : "#27632a",
            },
            text:{
                primary: "#333"
            },
            error    : red
        },
        status : {
            danger: 'orange'
        }
    },
  
    tech       : {
        palette: {
            type     : 'light',
            primary  : {
                light       : '#87efff',
                main        : '#4dbce9',
                dark        : '#008cb7',
                contrastText: '#fff'
            },
            secondary: {
                light: '#ffff83',
                main : '#d1e751',
                dark : '#9db516'
            }
        }
    }
 
};
