export const BLOCK_UI = 'UI BLOCK';
export const UNBLOCK_UI = 'UI UNBLOCK';

export function blockUI()
{
    return {
        type: BLOCK_UI
    }
}
export function unblockUI()
{
    return {
        type: UNBLOCK_UI
    }
}
