import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Avatar, Button,Icon, ListItemIcon, ListItemText, Popover, MenuItem, Typography} from '@material-ui/core';
import {bindActionCreators} from 'redux';
import { FuseAnimate} from '@fuse';
import * as authActions from 'auth/store/actions';
const styles = theme => ({
    root     : {
        display   : 'flex',
        alignItems: 'center',
        width     : '100%',
        backgroundColor: theme.palette.secondary.main
    },
    seperator: {
        width          : 1,
        height         : 64,
        backgroundColor: theme.palette.divider
    },
    logo      : {},
    logoIcon  : {
        width     : 108,
        height    : 26,
        "margin-left": 0,
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing  : theme.transitions.easing.easeInOut
        }),
    
    },
    moduleName:{
        "margin-top":"4px"
    },
    smallMenu:{
        "font-size":"13px !important"
    }
});

class MainToolbar extends Component {
    state = {
        userMenu: null
    };
    userMenuClick = event => {
        this.setState({userMenu: event.currentTarget});
    };

    userMenuClose = () => {
        this.setState({userMenu: null});
    };

    render()
    {
        const {classes, user, logout} = this.props;
        const {userMenu} = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-row")}>
                <div className={classNames(classes.logo, "flex flex-1 px-24")}>
                    <img className={classNames(classes.logoIcon, "logo-icon")} src="assets/images/logos/logo2.png" alt="logo"/>
                    <Typography className={classNames(classes.moduleName, "text-14 ml-16 font-light logo-text")} color="primary" ></Typography>
                </div>
         
                <div className="flex ">
                    <FuseAnimate delay={300}>
                            <Button className="h-64" onClick={this.userMenuClick}>
                                {user.data.photoURL ?
                                    (
                                        <Avatar className="" alt="user photo" src={user.data.photoURL}/>
                                    )
                                    :
                                    (
                                        <Avatar className=""    src={ "assets/images/avatars/profile.jpg"}>
                                   
                                        </Avatar>
                                    )
                                }

                                <div className="hidden md:flex flex-col ml-12 items-start">
                                    <Typography component="span" className="normal-case font-500 flex" color="primary">
                                        {user.data.displayName}
                                    </Typography>
                                    <Typography className="text-11 capitalize"  color="primary">
                                        {user.role}
                                    </Typography>
                                </div>

                                <Icon className="text-16 ml-12 hidden sm:flex" variant="action" color="primary">keyboard_arrow_down</Icon>
                            </Button>
                        </FuseAnimate>
                        
                        <Popover
                            open={Boolean(userMenu)}
                            anchorEl={userMenu}
                            onClose={this.userMenuClose}
                            anchorOrigin={{
                                vertical  : 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical  : 'top',
                                horizontal: 'center'
                            }}
                            classes={{
                                paper: "py-8"
                            }}
                        >
                            
                                <React.Fragment>
                                
                                    <MenuItem
                                        onClick={() => {
                                            logout();
                                            this.userMenuClose();
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Icon>exit_to_app</Icon>
                                        </ListItemIcon>
                                        <ListItemText className={classNames(classes.smallMenu, "pl-0")}  disableTypography={true} primary="Cerrar sesión"/>
                                    </MenuItem>
                                </React.Fragment>
                        
                        </Popover>
                        <div className={classes.seperator}/>
                 
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        logout          : authActions.logoutUser
    }, dispatch);
}
function mapStateToProps({auth})
{
    return {
        user: auth.user
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(MainToolbar));
