import 'babel-polyfill'
import 'typeface-poppins';
import React from 'react';
import ReactDOM from 'react-dom';
import history from './history';

import './fuse-configs/momentConfig';
import './styles/index.css';

//style
import {create} from 'jss';
import JssProvider from 'react-jss/lib/JssProvider';

import {createGenerateClassName, jssPreset} from '@material-ui/core/styles';

import {FuseLayout, FuseTheme, FuseAuthorization} from '@fuse';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import {routes} from './fuse-configs/fuseRoutesConfig';

import MainNavbarContent from './main/MainNavbarContent';
import MainToolbar from './main/MainToolbar';
import jssExtend from 'jss-extend'
import {Auth} from 'auth';
import store from 'store';
import * as serviceWorker from './serviceWorker';


const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();

ReactDOM.render( <JssProvider jss={jss} generateClassName={generateClassName}>
    <Provider store={store}>
        
            <Router history={history}>
            <Auth>
                <FuseAuthorization routes={routes}>
                    <FuseTheme>
                        <FuseLayout
                            routes={routes}
                            toolbar={
                                <MainToolbar/>
                            }
                          
                            navbarContent={
                                <MainNavbarContent/>
                            }
                          
                        >
                        </FuseLayout>
                    </FuseTheme>
                </FuseAuthorization>
                </Auth>
            </Router>
      
    </Provider>
</JssProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
