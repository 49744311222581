
export const ModulesConfig = [
    //alicorp apps 
    {
        id:'GestionDoc',
        nombre:'Gestión de documentos',
        icon:'gestiondoc.png',
        color:'#EB1C2C',
        order:1,
        url:process.env.REACT_APP_GESTIONDOC
    },
    //hr apps
    {
        id:'Workplace',
        nombre:'Workplace',
        icon:'workplace.png',
        color:'#EB1C2C',
        order:2,
        url:process.env.REACT_APP_WORKPLACE
    },
    {
        id:'ModeloCrece',
        nombre:'Modelo crece',
        icon:'modelocrece.png',
        color:'#7FC242',
        order:3,
        url:process.env.REACT_APP_MODELOCRECE
    },
    {
        id:'Degreed',
        nombre:'Degreed',
        icon:'degreed.png',
        color:'#7FC242',
        order:4,
        url:process.env.REACT_APP_DEGREE
    },
    //hr apps apps 
    {
        id:'EntrevistasSalida',
        nombre:'Entrevista de Salida',
        icon:'ceces.png',
        color:'#F89C1D',
        order:5,
        url:process.env.REACT_APP_CECES
    },
    {
        id:'PortalRRHHMFormativas',
        nombre:'Modalidades formativas',
        icon:'modalidadesformativas.png',
        color:'#F89C1D',
        order:6,
        url:process.env.REACT_APP_MODALIDADESFORMATIVAS
    },
    {
        id:'PortalRRHHGestionCapacitacion',
        nombre:'Gestión de capacitaciones',
        icon:'gestioncapacitacion.png',
        color:'#F89C1D',
        order:7,
        url:process.env.REACT_APP_GESTIONCAPACITACION
    },
    {
        id:'PortalRRHHEvaluacionOperarios',
        nombre:'Evaluacion operarios',
        icon:'evaluacionoperarios.png',
        color:'#F89C1D',
        order:8,
        url:process.env.REACT_APP_EVALUACIONOPERARIOS
    },
    {
        id:'Cafeterias',
        nombre:'Cafeterias',
        icon:'cafeterias.png',
        color:'#F89C1D',
        order:9,
        url:process.env.REACT_APP_CAFETERIAS
    },
    {
        id:'CapacitacionObreros',
        nombre:'Gestión de capacitación obreros',
        icon:'capobreros.png',
        color:'#F89C1D',
        order:10,
        url:process.env.REACT_APP_CAPOBREROS
    }, 
     {
        id:'Calibracion',
        nombre:'Calibración RR.HH',
        icon:'calibracion.png',
        color:'#F89C1D',
        order:11,
        url:process.env.REACT_APP_CALIBRACION
    },
    {
        id:'Alitalento',
        nombre:'Alitalento',
        icon:'alitalento.png',
        color:'#F89C1D',
        order:12,
        url:process.env.REACT_APP_ALITALENTO
    },
    //internal config apps
    {
        id:'GestionOrg',
        nombre:'Gestión Organizacional',
        icon:'gestionorg.png',
        color:'#333',
        order:13,
        url:process.env.REACT_APP_GESTIONORG
    },
    {
        id:'WorkplaceAPI',
        nombre:'Workplace API',
        icon:'workplaceapi.png',
        color:'#333',
        order:14,
        url:process.env.REACT_APP_WORKPLACEAPI
    },
    {
        id:'DegreeAPI',
        nombre:'Degreed API',
        icon:'degreedapi.png',
        color:'#333',
        order:15,
        url:process.env.REACT_APP_DEGREEAPI
    },
    {
        id:'AlicorpAPI',
        nombre:'Alicorp API',
        icon:'alicorpapi.png',
        color:'#333',
        order:16,
        url:process.env.REACT_APP_ALICORPAPI
    },
  
  
  
  
   
]