


export const fuseNavigationConfig = [
    {
        
        'id'      : 'inicio',
        'title'   : 'Inicio',
        'type'    : 'item',
        'icon'    : 'apps',
        'url'  : '/dashboard'
    },
   
    
  
];
