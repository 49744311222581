import React,{Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AppBar from './AppBar';
import classNames from 'classnames';
const styles = theme => ({
    texto: {
      
        fontSize:'2.2rem',
        lineHeight:'4.4rem',
        fontWeight:'bolder',
        color:'#ED2835'
    },
    root:{
        marginTop:'4rem',
        marginLeft:'6rem',
        paddingBottom: "60px"
    }
  
})
class AppMenu extends Component {

    render(){
        const  {classes} = this.props;
        return(
            <React.Fragment>
            <div  className={classNames(classes.root)}>
            <Typography  className={classNames(classes.texto)} >Mis aplicaciones</Typography>
            <AppBar></AppBar>
            </div>
            </React.Fragment>
        )
    }
}
export default  withStyles(styles) (AppMenu)