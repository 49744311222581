import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse/index';
import {DashboardConfig} from 'main/content/dashboard/DashboardConfig';

const routeConfigs = [
    DashboardConfig
];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path     : '/',
       // exact    : true,
        component: () => <Redirect to="/inicio"/>
    },
    
    {
        component: () => <Redirect to="/pages/errors/error-404"/>
    }
];
