

import React,{Component} from 'react'
import AppItem from './AppItem';
import {connect} from 'react-redux';
import {ModulesConfig} from './ModulesConfig';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import classNames from 'classnames';
const styles = theme => ({

      root:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap:'wrap',
        marginTop:'10px'
      }
    
})
class AppBar extends Component{


    render(){
        const {allowedModules,classes} = this.props;
        console.log(allowedModules)
        const listItems =() =>{
            if( allowedModules === undefined){
                return ("")
            }else{
                return (allowedModules.map(function(item,index){
                    var datos=  _.find(ModulesConfig,{'id':item});
                        if(datos !== undefined){
                        return( 
                            <AppItem 
                            key={item}
                            nombre={datos.nombre}
                            image={datos.icon}
                            url={datos.url}
                            order={datos.order}
                             color={datos.color}></AppItem>)
                        }else{
                            return("")
                        }
                    })
                )
            }
        }
        return(
            <div  className={classNames(classes.root)}>
            {listItems()}
            </div>
        )
    }
}
function mapStateToProps({auth})
{
    return {
        allowedModules : auth.user.allowedModules
    }
}
export default withStyles(styles)(connect(mapStateToProps, null)(AppBar));