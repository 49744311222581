import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './blockui.css';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';


const style = {
    root:{
        color:'green',
        height:'100%',
        width:'100%'
    }
} 

class BlockUI extends React.Component {
 
 
  render() {
    const {uiblock,classes,children} = this.props;
    return (
    
        <BlockUi className={classes.root} blocking={(uiblock>0 ? true:false)} message="Cargando" keepInView>
    
        {children}
        </BlockUi>
    

    );
  }
}
function mapStateToProps({ commonUi }) {
    return {
        uiblock: commonUi.blockui.uiblock
    }
}
export default withStyles(style)(withRouter(connect(mapStateToProps,null)(BlockUI)));
