export let config = {
    clientUrl:process.env.REACT_APP_CLIENTURL,
    apiUrl:process.env.REACT_APP_APIURL,
   RecaptchaKey:process.env.REACT_APP_RECAPTCHA,
   hubUrl:process.env.REACT_APP_HUBURL,
   email:"rrhh@alicorp.com.pe",
   auth0:{
    domain     :process.env.REACT_APP_AUTH0_DOMAIN ,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE ,//api al cual se quiere accder
    clientId   : process.env.REACT_APP_AUTH0_CLIENTID,  
   callbackUrl: process.env.REACT_APP_AUTH0_CALLBACKURL,//"http://localhost:3000/callback"
    logoutUrl :process.env.REACT_APP_AUTH0_LOGOUTURL,
    }

};
