import { combineReducers } from 'redux';
import fuse from './fuse';
import commonUi from './common';
import auth from 'auth/store/reducers/index';


const rootReducer = combineReducers({
    auth,
    fuse,
    commonUi,

});

export default rootReducer;