import history from 'history.js';
import auth0Service from 'auth0Service';
import _ from 'lodash'; 
export const SET_USER_DATA = '[USER] SET DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const GET_ROL_FROM_DB = '[USER] GET_ROL_FROM_DB';
export const SET_ROL_FROM_DB = '[USER] SET_ROL_FROM_DB';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';

/**
 * Set user data from Auth0 token data
 */
export function setUserDataAuth0(tokenData)
{
   
    var modules =  getAllowedModules(tokenData)
    var user = {
        role: 'usuario',
        from: 'auth0',
        data: {
            displayName: tokenData['https://alirrhh.com/nombrePreferido'],
          
            email      : tokenData.email,
            settings   : (tokenData.user_metadata && tokenData.user_metadata.settings) ? tokenData.user_metadata.settings : {},
            shortcuts  : (tokenData.user_metadata && tokenData.user_metadata.shortcuts) ? tokenData.user_metadata.shortcuts : []
        },
        allowedModules:modules
    };
    return {
        type   : SET_USER_DATA,
        payload: user
    }
}
function getAllowedModules(data){
   
    var lista = [];
    var baseClaim ="https://alirrhh.access.com/";

  var listaPre =  _.map(data,function(value,key){
        return key;
    })

    for(var i =0 ;i<listaPre.length; i++){
     
        if(listaPre[i].startsWith(baseClaim)){
            lista.push(listaPre[i].substring(baseClaim.length,listaPre[i].legnth))
        }
    }
    return lista
}
export function getRoleFromDatabase(){
    return {
        type   : GET_ROL_FROM_DB
    }
}
export function setRoleFromDatabase(data){
    return {
        type   : SET_ROL_FROM_DB,
        payload : data
    }
}
export function logoutUser()
{
    auth0Service.signOut();
    history.push({
        pathname: '/'
    });


        return({
            type: USER_LOGGED_OUT
        })
 }
