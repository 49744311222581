import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FusePageSimple } from '@fuse';
import InicioImagen from './InicioImagen';
import AppMenu from './AppMenu';
import classNames from 'classnames';
const styles = theme => ({
    layoutRoot: {
        backgroundColor: '#FBF7EE'
    },
    appmenu: {

    }
});

class Dashboard extends Component {

    render() {
        const { classes } = this.props;
        return (
            <FusePageSimple
                className={classNames(classes.layoutRoot)}
                classes={{
                    root: classes.layoutRoot
                }}
                // header={
                //     <div className="p-24"><h4>Header</h4></div>
                // }
                contentToolbar={
                    <InicioImagen></InicioImagen>
                }
                content={
                    <AppMenu className={classNames(classes.appmenu)} > </AppMenu>
                }
            />
        )
    }
}

export default withStyles(styles, { withTheme: true })(Dashboard);