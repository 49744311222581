import * as Actions from './../../actions/index';


const initialState = {
 uiblock: 0
};

const blockui = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.BLOCK_UI:
        {
            return {
                ...state,
                uiblock: state.uiblock + 1
            };
        }
        case Actions.UNBLOCK_UI:
        {
            return {
                ...state,
                uiblock: state.uiblock -1
            };
        }
        default:
        {
            return state;
        }
    }
};

export default blockui;