


import auth0 from 'auth0-js';
import {config} from './../fuse-configs/gestionOrgConfig';
import {AxiosInstance} from 'store/api/baseApi'
class auth0Service {

    //                 scope: 'openid profile email  picture update:current_user_metadata create:current_user_metadata read:current_user'
  
    constructor() {
        if ( auth0Service.instance )
        {
            return auth0Service.instance;
        }
    
        this.auth0 = new auth0.WebAuth({
            // the following three lines MUST be updated
            domain: config.auth0.domain,
         //   audience: config.auth0.audience,
            clientID: config.auth0.clientId,
            redirectUri:  config.auth0.callbackUrl,
            responseType: 'token id_token',
            scope: 'openid profile'
          });
          this.getProfile = this.getProfile.bind(this);
          this.handleAuthentication = this.handleAuthentication.bind(this);
          this.isAuthenticated = this.isAuthenticated.bind(this);
          this.signIn = this.signIn.bind(this);
          this.signOut = this.signOut.bind(this);

          auth0Service.instance =this;
      }
    


      getProfile() {
        return this.profile;
      }
    
      getIdToken() {
        return this.idToken;
      }
      getAccessToken(){
        return this.accessToken;
      }
      handleAuthentication() {
        return new Promise((resolve, reject) => {
          this.auth0.parseHash((err, authResult) => {
            if (err) return reject(err);
            if (!authResult || !authResult.idToken) {
              return reject(err);
            }
            this.setSession(authResult);
            resolve();
          });
        })
      }
    
      isAuthenticated() {
        return new Date().getTime() < this.expiresAt;
      }
    
      setSession(authResult, step) {
  
        this.idToken = authResult.idToken;
        //this.accessToken =authResult.accessToken;
        this.profile = authResult.idTokenPayload;
        AxiosInstance.defaults.headers.common['Authorization'] = "Bearer " +authResult.accessToken;
      
        // set the time that the id token will expire at
        this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
  
        this.scheduleRenewal()
      }
      getUserData(){
  
      }
      signIn() {
        this.auth0.authorize();

      }
      scheduleRenewal=()=> {
        const expiresAt = this.expiresAt;
        const delay = expiresAt - Date.now();
        if (delay > 0) {
          this.tokenRenewalTimeout = setTimeout(() => {
            this.renewToken();
          }, delay);
        }
      }
      renewToken=()=> {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
              console.log(err);
            } else {
              this.setSession(result);
            }
          }
        );
      }
      signOut() {
        this.auth0.logout({
          returnTo: config.auth0.logoutUrl ,// AUTH_CONFIG.callbackUrl,
          clientID:config.auth0.clientId,
        });
      }
    
      silentAuth() {
        return new Promise((resolve, reject) => {
          this.auth0.checkSession({}, (err, authResult) => {
            if (err) return reject(err);
            this.setSession(authResult);
            resolve();
          });
        });
      }
}

const instance = new auth0Service();

export default instance;
