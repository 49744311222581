import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as Actions from 'store/actions';
import * as AuthActions from './store/actions'
import auth0Service from 'auth0Service';
import { withRouter} from 'react-router-dom';
import {blockUI,unblockUI} from 'store/actions';
import {FuseSplashScreen} from '@fuse';
class Auth extends Component {

    state = {
        loggedIn: false
    }
   async componentDidMount()
    {
        this.props.blockUi()
        try {
            await auth0Service.silentAuth();
            this.forceUpdate();
          }  catch (err) {

        }
        this.props.unblockUi();
       await this.auth0Check();
    }

     auth0Check = async () => {
      

        if(this.props.location.hash != null && this.props.location.hash.includes("access_token")){

        }

        if (this.props.location.pathname !== '/callback'){
            this.props.blockUi()
            if ( auth0Service.isAuthenticated() )
            {
                //this.props.showMessage({message: 'Ha iniciado sesion'});
            
            let profile = auth0Service.getProfile();
            this.props.setUserDataAuth0(profile);
            this.setState({loggedIn : true})
            this.props.getRoleFromDatabase();
            
            } else{
                auth0Service.signIn();
            }
            this.props.unblockUi();
        }
      
      
    };

   

    render()
    {
        const {children} = this.props;
        const {loggedIn} = this.state;
          if(loggedIn){
            return (
                <React.Fragment>
                
                    {children}
                </React.Fragment>
            );       
        }else{
            return(
                <FuseSplashScreen></FuseSplashScreen>
            );
          
        }
       
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
            showMessage        : Actions.showMessage,
            hideMessage        : Actions.hideMessage,
            setUserDataAuth0 :   AuthActions.setUserDataAuth0,
            getRoleFromDatabase : AuthActions.getRoleFromDatabase,
            blockUi :blockUI,
            unblockUi :unblockUI

        },
        dispatch);
}

export default withRouter(connect(null, mapDispatchToProps)(Auth));

//export default connect(null, mapDispatchToProps)(Auth);
