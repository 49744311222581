
import React,{Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
    mainimage: {
   
    //maxHeight:'400px',
    [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
       // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
       // backgroundColor: green[500],
      },
    },
    imageStyle:{
        minHeight:'200px'
    }
});

class InicioImagen extends Component{

    render(){
        const { classes} = this.props;
        return(<div  className={classNames(classes.mainimage)} >
                <img className={classNames(classes.imageStyle)}
                 align="top" src="assets/images/banner.png" alt="mainbaner"/>
            </div>)
    }
}
export default withStyles(styles)(InicioImagen)