import {applyMiddleware, createStore} from 'redux';
import reducers from 'store/reducers';
import { createLogger } from 'redux-logger';
//import createSagaMiddleware from 'redux-saga';
//import rootSaga from './store/sagas';


const loggerMiddleware = createLogger();
//const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    reducers,
    applyMiddleware(
       loggerMiddleware,
    //   sagaMiddleware
    )
);
//const store = createStore(reducers, enhancer);

export default store;
//sagaMiddleware.run(rootSaga)