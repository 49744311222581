
import React,{Component} from 'react'
import {IconButton,Typography} from '@material-ui/core';
import classNames from 'classnames';
import Color from 'color';
import injectSheet from 'react-jss';

const styles = ({
  
    boton: {
        borderRadius:'60px 0px 60px 0px ',
        height:'120px',
        width:'120px',
        color:'#fff',
        margin:'15px 0px 20px 0px',
      //  boxShadow: 'none',
       // textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        backgroundColor:props => Color(props.color).hsl().string(),  
        borderColor: props => Color(props.color).lighten(0.2).hsl().string(),
        '&:hover': {
          backgroundColor: props => Color(props.color).lighten(0.2).hsl().string(),
         // borderColor: Color(props.color).lighten(0.6).hsl().string(),
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#0062cc',
          borderColor: '#005cbf',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      },
    
      nombre:{
        textAlign:'center',
        fontSize:'1.3rem',
        width:'110px'
      },
      root: props =>({
      margin:'0px 50px 20px 0px',
        order: props.order
      })
    
})
class AppItem extends Component{

    render(){
        const {nombre,image,classes,url}=this.props;
        return(
            <div  className={classNames(classes.root)}>
                <a href={url}>
            <IconButton variant="contained"
            className={classNames(classes.boton)}
           >
            <img className={classNames(classes.imageStyle)}
                 align="top" src={"assets/images/icons/"+image} alt={nombre}/>
            </IconButton>
            </a>
          <Typography className={classNames(classes.nombre)} >{nombre}</Typography>
          </div>
        )
    }
}
export default injectSheet(styles)(AppItem);